import { FC } from "react";
import styled from "styled-components";
import { Box } from "@ui";

export const App:FC = () => {
	return (
		<Root>
			smart stuff
		</Root>
	);
};

const Root = styled(Box.Abs)`
	overflow:hidden;
`;
